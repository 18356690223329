export const environment = {
  userPoolId: 'ap-south-1_Kn9MDa1U6',
  userPoolClientId: '174du85ie9j7ons5qivq05ks6',
  cognitoDomain: 'stg-sales-analytics.auth.ap-south-1.amazoncognito.com',
  scopes: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
  redirectSignIn: [`${window.location.origin}`],
  redirectSignOut: [`${window.location.origin}/login`],
  responseType: 'token',
  zohoCrmBaseUrl: 'https://crm.zoho.com/crm/org709483323',
  googleMeasurementId: 'G-N7Z6G2E5GW',
};
